.zk-device {
  display: block;

  &-approval {
    position: absolute;
    top: 15px;
    right: 40px;
  }

  &__detail {
    min-height: calc(100vh - 145px);
    display: block;
    overflow: hidden;
    background: #fff;
    padding: 24px;
    &.has-bar {
      min-height: calc(100vh - 213px);
    }
    .ant-tabs-content {
      position: inherit;
    }
    .ant-form-item-control-input {
      flex: 1;
    }
    &-top {
      display: flex;
      padding-top: 20px;

      .left {
        flex: 1;
        display: flex;

        .avatar {
          width: 60px;
          display: block;
        }

        .info {
          flex: 1;
          display: block;
          line-height: 30px;
          padding-left: 10px;

          p {
            margin: 0;
          }
        }
      }

      .right {
        display: block;
      }
    }

    &-parmas {
      .ant-form-item-label {
        text-align: left;
      }
    }
  }

  &__detail-drawer {
    ul {
      display: block;

      li {
        padding: 10px 0;
        display: flex;
        border-bottom: 1px solid #f0f0f0;
        padding-left: 24px;

        > span {
          color: #999;
          padding-right: 20px;
          font-size: 14px;
        }

        i {
          font-size: 30px;
          color: #999;

          &.green {
            color: var(--primary-color);
          }
        }
      }
    }
    .bio-template_sub-list {
      display: block;
      overflow: hidden;
      &-li {
        width: 25%;
        float: left;
        display: block;
        text-align: center;
        .icon i {
          font-size: 46px;
          color: #ccc;
          &.green {
            color: var(--primary-color);
          }
        }
        p {
          color: #333;
          padding-top: 10px;
        }
      }
    }
  }

  &__upload-status {
    display: block;

    li {
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px;

      span {
        display: block;

        &:first-child {
          flex: 1;
        }
      }
    }
  }
}

@media screen and (min-height: 600px) {
  .zk-device__detail-parmas-btn {
    position: absolute !important;
    bottom: 40px;
    left: 40px;
  }
}

.zk-select-person {
  display: block;

  &__card {
    display: block;
    border: 1px solid #d9d9d9;

    &-top {
      display: block;
      background: #fafafa;
      padding: 10px;
    }

    &-content {
      display: block;
      border-top: 1px solid #d9d9d9;
      background: #fff;
      padding: 10px;
      height: 200px;

      > div {
        overflow-y: auto;
        width: 100%;
        height: 100%;
      }
    }

    &.last {
      border-left: none;

      .zk-select-person__card-top {
        text-align: right;
      }
    }
  }
}

.ant-transfer-list-header {
  background: #fafafa !important;
}

.zk-bt__batch-upload {
  display: block;
  background: #fff;
  border-radius: 4px;
  min-height: 400px;
  height: calc(100vh - 220px);

  &__top {
    display: block;
    padding: 20px;
    height: calc(100% - 100px);
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    transition: all 0s;
    color: #999;

    > div {
      padding: 10px 10px 10px 0;
      &:first-child {
        flex: 1;
      }
    }

    .ant-btn.ant-btn-link {
      padding: 0;
      height: 22px;
    }
  }
}
.zk-device-modal__list {
  display: block;
  padding-bottom: 20px;
  min-height: 330px;
  &-ul {
    overflow: hidden;
    margin: 0 -5px;
    padding: 10px 0;
    height: 300px;
    overflow-y: auto;
    li {
      width: 50%;
      float: left;
      display: block;
      padding: 5px;
      box-sizing: border-box;
    }
  }
  &-li {
    display: flex;
    padding: 10px;
    border: 1px solid #efefef;
    cursor: pointer;
    * {
      padding: 0;
      margin: 0;
    }
    &-right {
      flex: 1;
      display: block;
      padding-left: 10px;
      h4 {
        display: block;
        color: #333;
      }
      p {
        font-size: 12px;
        color: #999;
        i {
          font-size: 12px;
          margin-right: 5px;
        }
        &.on {
          color: var(--primary-hover-color);
        }
      }
    }
    &:hover {
      border-color: var(--primary-hover-color);
    }
  }
  .ant-pagination {
    float: right;
  }
}

.zk-fingerprint-modal {
  display: block;
  text-align: center;
  &.flex {
    display: flex;
  }
  i {
    font-size: 100px;
    color: #ccc;
  }
  p span {
    color: var(--primary-hover-color);
    padding: 0 5px;
  }
}
.zk-select-device-modal {
  .ant-table-body {
    min-height: 110px;
  }
  .ant-table table {
    width: 100% !important;
  }
}
